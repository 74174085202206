import React, { useState, useEffect } from 'react';

import { Avatar, Divider, Box, Image, Switch, Card, Grid, Badge, Flex, Col } from '@mantine/core';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating'

import { FiLink, FiEdit, FiTrash, FiEye, FiMoreVertical, } from "react-icons/fi";

import Loading from '../../../../components/Loading/Loading';


// Utility-Service
import { getToken } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// API-Service
import { mechanicDetail, UpdateStatusService } from '../../../../service/mechanic';

// Redux-Settings
import {
    setMechanicDetail,
    selectStateData,
} from '../../../../redux/mechanicSlice';

// Auth-Image
import AuthImage from '../../../../components/AuthImage/AuthImage';

import PlaceHolderThumb from '../../../../assets/images/placeholderThumb.png';

const MechanicDetail = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const userDetail = useSelector(selectStateData);
    const { id } = useParams()

    const token = getToken();

    const getSingleData = async () => {
        const result = await mechanicDetail({ id, token });
        if (result?.data) {
            setLoading(false);
            dispatch(setMechanicDetail(result?.data.data))
        } else {
            setLoading(false);
            ErrorToast(result);
        }
    };
    useEffect(() => {
        getSingleData();
        const timeoutId = setTimeout(() => 3000);
        return function cleanup() {
            clearTimeout(timeoutId);
        };
    }, []);

    const statusUpdate = async (item) => {
        const statusUpdateresult = await UpdateStatusService({ id: item.id, token });
        if (statusUpdateresult?.data?.status) {
            ErrorToast(statusUpdateresult?.data?.message);
            SuccessToast()
        } else {
            ErrorToast(statusUpdateresult);
        }
    };

    // console.log("UserDetail =>", userDetail?.driving_license)


    if (userDetail?.mechanic_type === 'MECHANIC') {
        return (
            <Card>
                <Grid>
                    <Grid.Col xs={12}>
                        <h3>Profile Detail</h3>
                    </Grid.Col>
                    <Grid.Col xs={2}>
                        <a href={userDetail?.profile_pic} target="_blank" rel="noopener noreferrer">
                            <Avatar variant="outline" size="xl" color="violet" src={userDetail?.profile_pic} />
                        </a>
                        <p> <Badge color="orange">Mechanic</Badge></p>
                        <Rating readonly={userDetail.avg_rating >= 0} size={18} initialValue={userDetail.avg_rating} />
                    </Grid.Col>
                    <Grid.Col xs={2}>
                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>Company Name</h6>
                            <Divider my="sm" />
                            <p style={{ margin: '0' }}>{userDetail?.company_name ? userDetail?.company_name : '-'}</p>
                        </div>

                    </Grid.Col>
                    {/* <Grid.Col xs={2}>
                        <div>
                            <h6>Email</h6>
                            <Divider my="sm" />
                            <p>{userDetail?.email ? userDetail?.email : '-'}</p>
                        </div>
                    </Grid.Col> */}
                    <Grid.Col xs={2}>
                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>Phone</h6>
                            <Divider my="sm" />
                            <p style={{ margin: '0' }}>{userDetail?.phone ? userDetail?.phone : '-'}</p>
                        </div>
                    </Grid.Col>
                    {/* <Grid.Col xs={2}>
                        <div>
                            <h6>IC Number</h6>
                            <Divider my="sm" />
                            <h6>{userDetail?.ic_number ? userDetail?.ic_number : '-'}</h6>
                        </div>
                    </Grid.Col>
                    <Grid.Col xs={2}>
                        <div>
                            <h6>IC Images</h6>
                            <Divider my="sm" />
                            <h6>{userDetail?.ic_images ? userDetail?.ic_images : '-'}</h6>
                            <ul>
                                {userDetail?.ic_images?.map(item => (
                                    <li>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Grid.Col> */}
                    <Grid.Col xs={2}>
                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>SSM Number</h6>
                            <Divider my="sm" />
                            <p style={{ margin: '0' }}>{userDetail?.ssm ? userDetail?.ssm : '-'}</p>
                        </div>
                    </Grid.Col>
                    <Grid.Col xs={2}>
                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>IC Number</h6>
                            <Divider my="sm" />
                            <p style={{ margin: '0' }}>{userDetail?.ic_number ? userDetail?.ic_number : '-'}</p>
                        </div>
                    </Grid.Col>
                    <Grid.Col xs={2}>
                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>Wallet Amount</h6>
                            <Divider my="sm" />
                            {userDetail?.wallet_balance ? <p style={{ margin: '0' }}> {`RM ${userDetail.wallet_balance?.wallet_balance}`}</p> : <p> 0.00</p>}

                        </div>
                    </Grid.Col>
                    <Grid.Col xs={2}>
                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>Subscribe</h6>
                            <Divider my="sm" />
                            {userDetail?.is_subscribed === 1 ? <p style={{ margin: '0' }}>YES</p> : <p style={{ margin: '0' }}>NO</p>}

                        </div>
                    </Grid.Col>
                </Grid>


                {/* Additional Information Added */}
                <Grid style={{ marginTop: '20px ' }}>

                    <Grid.Col xs={6}>

                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>IC Image</h6>
                            <Divider my="sm" />
                            {userDetail && userDetail.ic_images && userDetail.ic_images.length > 0 ? (
                                <div className="ssmImageContainer">
                                    {userDetail.ic_images.map(item => (
                                        <div className="ssmClass" key={item.name}>
                                            <img
                                                src={item.path}
                                                alt="D"
                                                className="ssmImage"
                                            />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p>No IC Image</p>
                            )}
                        </div>


                    </Grid.Col>
                    <Grid.Col xs={6}>
                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>SSM Image</h6>
                            <Divider my="sm" />
                            {userDetail && userDetail.ssm_documents && userDetail.ssm_documents.length > 0 ? (
                                <div className="ssmImageContainer">
                                    {userDetail.ssm_documents.map(item => (
                                        <div className="ssmClass" key={item.id}>
                                            <img
                                                src={item.path}
                                                alt="SSM Document"
                                                className="ssmImage"
                                            />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p> No SSM Document</p>

                            )}
                        </div>
                    </Grid.Col>

                    <Grid.Col xs={6}>

                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>Driving Licence</h6>
                            <Divider my="sm" />
                            {userDetail && userDetail.driving_license && userDetail.driving_license.length > 0 ? (
                                <div className="ssmImageContainer">
                                    {userDetail.driving_license.map(item => (
                                        <div className="ssmClass" key={item.name}>
                                            <img
                                                src={item.path}
                                                alt="License"
                                                className="ssmImage"
                                            />
                                        </div>

                                    ))}

                                </div>
                            ) : (
                                <p>No Driving Licence</p>
                            )}
                        </div>
                    </Grid.Col>

                    <Grid.Col xs={6}>

                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>Current Utility Bill</h6>
                            <Divider my="sm" />
                            {userDetail && userDetail.utility_bills && userDetail.utility_bills.length > 0 ? (
                                <div className="ssmImageContainer">
                                    {userDetail.utility_bills.map(item => (
                                        <div className="ssmClass" key={item.name}>
                                            <img
                                                src={item.path}
                                                alt="Bill"
                                                className="ssmImage"
                                            />
                                        </div>

                                    ))}

                                </div>
                            ) : (
                                <p>No Utlity Bill</p>
                            )}
                        </div>
                    </Grid.Col>

                    <Grid.Col xs={6}>

                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>Qualification Certificate</h6>
                            <Divider my="sm" />
                            {userDetail && userDetail.qualification_certificate && userDetail.qualification_certificate.length > 0 ? (
                                <div className="ssmImageContainer">
                                    {userDetail.qualification_certificate.map(item => (
                                        <div className="ssmClass" key={item.name}>
                                            <img
                                                src={item.path}
                                                alt="Certificate"
                                                className="ssmImage"
                                            />
                                        </div>

                                    ))}

                                </div>
                            ) : (
                                <p>No Certificate</p>
                            )}
                        </div>

                    </Grid.Col>
                    <Grid.Col xs={6}>

                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>Liability Insurance</h6>
                            <Divider my="sm" />
                            {userDetail && userDetail.liability_insurance && userDetail.liability_insurance.length > 0 ? (
                                <div className="ssmImageContainer">
                                    {userDetail.liability_insurance.map(item => (
                                        <div className="ssmClass" key={item.name}>
                                            <img
                                                src={item.path}
                                                alt="Certificate"
                                                className="ssmImage"
                                            />
                                        </div>

                                    ))}

                                </div>
                            ) : (
                                <p>No Insurance</p>
                            )}
                        </div>
                    </Grid.Col>

                </Grid>
                <Grid>
                    {/* <Grid.Col xs={12}>
                        <div>
                            <h6>Experience Summary</h6>
                            <Divider my="sm" />
                            {userDetail?.experience_summary ? <p>{userDetail?.experience_summary}</p> : <p>--</p>}

                        </div>
                    </Grid.Col> */}
                    <Grid.Col xs={12}>
                        <div>
                            <h6 style={{ marginTop: '0.9rem', marginBottom: '0.5rem' }}>Services</h6>
                            <Divider my="sm" />
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {userDetail?.services?.map(item => (
                                    <div className='serviceClass'>

                                        <div>
                                            <span>
                                                <a href={item.image} target="_blank" rel="noopener noreferrer">
                                                    <Image
                                                        src={item.image}
                                                        alt="Avatar Thumb"
                                                        sx={(_) => ({
                                                            height: '60px',
                                                            width: '50px',
                                                            overflow: 'hidden',
                                                            margin: 'auto',
                                                        })}
                                                    />
                                                </a>
                                            </span>
                                            <p>{item.name}</p>

                                            <p>{item.other_services}</p>

                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>
                    </Grid.Col>

                </Grid>
            </Card >
        )
    }

    return (
        <>
            <Card>
                <Grid>
                    <Grid.Col xs={12}>
                        <h3>Profile Detail</h3>
                    </Grid.Col>
                    <Grid.Col xs={2}>
                        <a href={userDetail?.profile_pic} target="_blank" rel="noopener noreferrer">
                            <Avatar variant="outline" size="xl" color="violet" src={userDetail?.profile_pic} />
                        </a>
                        <p> <Badge color="orange">Freelancer</Badge></p>
                        <Rating readonly={userDetail.avg_rating >= 0} size={18} initialValue={userDetail.avg_rating} />
                    </Grid.Col>
                    <Grid.Col xs={2}>
                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>Legal Name</h6>
                            <Divider my="sm" />
                            <p style={{ margin: '0' }}>{userDetail?.legal_name ? userDetail?.legal_name : '-'}</p>
                        </div>
                    </Grid.Col>
                    {/* <Grid.Col xs={2}>
                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>Email</h6>
                            <Divider my="sm" />
                            <p>{userDetail?.email ? userDetail?.email : '-'}</p>
                        </div>
                    </Grid.Col> */}
                    <Grid.Col xs={2}>
                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>Phone</h6>
                            <Divider my="sm" />
                            <p style={{ margin: '0' }}>{userDetail?.phone ? userDetail?.phone : '-'}</p>
                        </div>
                    </Grid.Col>
                    <Grid.Col xs={2}>
                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>IC Number</h6>
                            <Divider my="sm" />
                            <p style={{ margin: '0' }}>{userDetail?.ic_number ? userDetail?.ic_number : '-'}</p>
                        </div>
                    </Grid.Col>
                    <Grid.Col xs={2}>
                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>SSM Number</h6>
                            <Divider my="sm" />
                            <p style={{ margin: '0' }}>{userDetail?.ssm ? userDetail?.ssm : '-'}</p>
                        </div>

                    </Grid.Col>
                    <Grid.Col xs={2}>
                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>Wallet Amount</h6>
                            <Divider my="sm" />
                            {userDetail?.wallet_balance ? <h5 style={{ margin: '0' }}> {`RM ${userDetail.wallet_balance?.wallet_balance}`}</h5> : <h5 style={{ margin: '0' }}> 0.00</h5>}

                        </div>
                    </Grid.Col>
                    <Grid.Col xs={12}>
                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>Experience Summary</h6>
                            <Divider my="sm" />
                            {userDetail?.experience_summary ? <p style={{ margin: '0' }}>{userDetail?.experience_summary}</p> : <p style={{ margin: '0' }}>--</p>}

                        </div>
                    </Grid.Col>
                </Grid>

                {/* Additional Information Added */}
                <Grid style={{ marginTop: '20px ' }}>

                    <Grid.Col xs={6}>

                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>IC Image</h6>
                            <Divider my="sm" />
                            {userDetail && userDetail.ic_images && userDetail.ic_images.length > 0 ? (
                                <div className="ssmImageContainer">
                                    {userDetail.ic_images.map(item => (
                                        <div className="ssmClass" key={item.name}>
                                            <img
                                                src={item.path}
                                                alt="D"
                                                className="ssmImage"
                                            />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p>No IC Image</p>
                            )}
                        </div>


                    </Grid.Col>
                    <Grid.Col xs={6}>
                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>SSM Image</h6>
                            <Divider my="sm" />
                            {userDetail && userDetail.ssm_documents && userDetail.ssm_documents.length > 0 ? (
                                <div className="ssmImageContainer">
                                    {userDetail.ssm_documents.map(item => (
                                        <div className="ssmClass" key={item.id}>
                                            <img
                                                src={item.path}
                                                alt="SSM Document"
                                                className="ssmImage"
                                            />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p> No SSM Document</p>
                            )}
                        </div>
                    </Grid.Col>

                    <Grid.Col xs={6}>

                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>Driving Licence</h6>
                            <Divider my="sm" />
                            {userDetail && userDetail.driving_license && userDetail.driving_license.length > 0 ? (
                                <div className="ssmImageContainer">
                                    {userDetail.driving_license.map(item => (
                                        <div className="ssmClass" key={item.name}>
                                            <img
                                                src={item.path}
                                                alt="License"
                                                className="ssmImage"
                                            />
                                        </div>

                                    ))}

                                </div>
                            ) : (
                                <p>No Driving Licence</p>
                            )}
                        </div>
                    </Grid.Col>

                    <Grid.Col xs={6}>

                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>Current Utility Bill</h6>
                            <Divider my="sm" />
                            {userDetail && userDetail.utility_bills && userDetail.utility_bills.length > 0 ? (
                                <div className="ssmImageContainer">
                                    {userDetail.utility_bills.map(item => (
                                        <div className="ssmClass" key={item.name}>
                                            <img
                                                src={item.path}
                                                alt="Bill"
                                                className="ssmImage"
                                            />
                                        </div>

                                    ))}

                                </div>
                            ) : (
                                <p>No Utlity Bill</p>
                            )}
                        </div>
                    </Grid.Col>

                    <Grid.Col xs={6}>

                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>Qualification Certificate</h6>
                            <Divider my="sm" />
                            {userDetail && userDetail.qualification_certificate && userDetail.qualification_certificate.length > 0 ? (
                                <div className="ssmImageContainer">
                                    {userDetail.qualification_certificate.map(item => (
                                        <div className="ssmClass" key={item.name}>
                                            <img
                                                src={item.path}
                                                alt="Certificate"
                                                className="ssmImage"
                                            />
                                        </div>

                                    ))}

                                </div>
                            ) : (
                                <p>No Certificate</p>
                            )}
                        </div>

                    </Grid.Col>
                    <Grid.Col xs={6}>

                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>Liability Insurance</h6>
                            <Divider my="sm" />
                            {userDetail && userDetail.liability_insurance && userDetail.liability_insurance.length > 0 ? (
                                <div className="ssmImageContainer">
                                    {userDetail.liability_insurance.map(item => (
                                        <div className="ssmClass" key={item.name}>
                                            <img
                                                src={item.path}
                                                alt="Certificate"
                                                className="ssmImage"
                                            />
                                        </div>

                                    ))}

                                </div>
                            ) : (
                                <p>No Insurance</p>
                            )}
                        </div>
                    </Grid.Col>

                </Grid>



                <Grid>
                    <Col>
                        <div>
                            <h6 style={{ marginTop: '0.9rem', marginBottom: '0.5rem' }}>Services</h6>
                            <Divider my="sm" />
                            {userDetail && userDetail.services && userDetail.services.length > 0 ? (
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                    {userDetail.services.map(item => (
                                        <div className='serviceClass' key={item.id}>
                                            <div>
                                                <span>
                                                    <Image
                                                        src={item.image}
                                                        alt="Avatar Thumb"
                                                        sx={(_) => ({
                                                            height: '60px',
                                                            width: '60px',
                                                            overflow: 'hidden',
                                                            margin: 'auto',
                                                        })}
                                                    />
                                                </span>
                                                <p>{item.name}</p>
                                                {item?.other_services &&
                                                    <p>{item.other_services}</p>
                                                }

                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p>No services available</p>
                            )}
                        </div>

                    </Col>
                </Grid>
            </Card>
        </>
    )
};

export default MechanicDetail;