import axios from 'axios';

const baseUrl = process.env.REACT_APP_APP_API_ENDPOINT

export const dataListingServiceUser = ({ token }) =>
  axios.get(`${baseUrl}/admin/customer-variable/list`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    }, 
  }); 
  
  export const dataListingServiceUserWebpageAboutUs = () =>
  axios.get(`${baseUrl}/customer/additional/variables/about_us`, {
    headers: {
       Accept: 'application/json',
    }, 
  }); 
  
  export const dataListingServiceUserWebpageAboutUsMalay = () =>
    axios.get(`${baseUrl}/customer/additional/variables/about_us_malay`, {
      headers: {
         Accept: 'application/json',
      }, 
    });

  export const dataListingServiceUserWebpagePolicy = () =>
  axios.get(`${baseUrl}/customer/additional/variables/privacy_policy`, {
    headers: {
       Accept: 'application/json',
    }, 
  });  

  export const dataListingServiceUserWebpagePolicyMalay = () =>
    axios.get(`${baseUrl}/customer/additional/variables/privacy_policy_malay`, {
      headers: {
         Accept: 'application/json',
      }, 
    }); 

  export const dataListingServiceUserWebpageTermCondition = () =>
  axios.get(`${baseUrl}/customer/additional/variables/terms_and_conditions`, {
    headers: {
       Accept: 'application/json',
    }, 
  });   

  export const dataListingServiceUserWebpageTermConditionMalay = () =>
    axios.get(`${baseUrl}/customer/additional/variables/terms_and_conditions_malay`, {
      headers: {
         Accept: 'application/json',
      }, 
    });  

  export const CreateDataServiceUser = ({ token }, strings) =>
  axios.post(
    `${baseUrl}/admin/customer-variable/store`, strings,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'multipart/form-data'
        // 'application/json',
      },
    }
  );

  export const dataListingServiceMechanic = ({ token }) =>
  axios.get(`${baseUrl}/admin/mechanic-variable/list`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    }, 
  });    

  export const dataListingServiceMechanicWebpageTerm = () =>
  axios.get(`${baseUrl}/mechanic/additional/variables/terms_and_conditions`, {
    headers: {
      Accept: 'application/json',
    }, 
  });  

  export const dataListingServiceMechanicWebpageTermMalay = () =>
    axios.get(`${baseUrl}/mechanic/additional/variables/terms_and_conditions_malay`, {
      headers: {
        Accept: 'application/json',
      }, 
    }); 

  export const dataListingServiceMechanicWebpageAboutUs = () =>
  axios.get(`${baseUrl}/mechanic/additional/variables/about_us`, {
    headers: {
      Accept: 'application/json',
    }, 
  });  

  export const dataListingServiceMechanicWebpageAboutUsMalay = () =>
    axios.get(`${baseUrl}/mechanic/additional/variables/about_us_malay`, {
      headers: {
        Accept: 'application/json',
      }, 
    }); 

  export const dataListingServiceMechanicWebpagePolicy = () =>
  axios.get(`${baseUrl}/mechanic/additional/variables/privacy_policy`, {
    headers: {
      Accept: 'application/json',
    }, 
  }); 

  export const dataListingServiceMechanicWebpagePolicyMalay = () =>
    axios.get(`${baseUrl}/mechanic/additional/variables/privacy_policy_malay`, {
      headers: {
        Accept: 'application/json',
      }, 
    }); 

export const CreateDataServiceMechanic = ({ token }, strings) =>
  axios.post(
    `${baseUrl}/admin/mechanic-variable/store`, strings,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'multipart/form-data'
        // 'application/json',
      },
    }
  );

export const UpdateStatusService = ({ id, token }) =>
  axios.post(
    `${baseUrl}/admin/mechanic-variable/status/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

export const getSingleDataService = ({ id, token }) =>
  axios.get(`${baseUrl}/admin/mechanic-variable/single/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });


export const UpdateSingleDataService = (
  { token, id },
  formData,
  // const id = formData.get('id')
) =>
  axios.post(
    `${baseUrl}/admin/mechanic-variable/update/${id}`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'multipart/form-data',
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const dataRemoveService = ({ id, token }) =>
  axios.delete(
    `${baseUrl}/admin/mechanic-variable/delete/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
