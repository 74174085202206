import React, { useState, useCallback } from 'react';
import { TextInput, Textarea, Select, MultiSelect, Card, Grid, Calendar, Box, Button, Modal, Group, Notification } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IconCheck, IconX } from '@tabler/icons';
import moment from 'moment'

import Loading from '../../../../components/Loading/Loading';

// API-Service   
import { dataListingService, CreateDataService } from '../../../../service/couponService';

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// Redux-Settings
import { setDataList, setTotalEntry, setFilteredList } from '../../../../redux/couponSlice';

const Create = () => {
  const [startDate, setStartDate] = useState(new Date());

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [couponType, setSelectedCoupon] = useState([]);
  const [selectedDays, setDays] = useState([]);
  const token = getToken();
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      code: '',
      discount_type: null,
      amount: '',
      start_date: '',
      end_date: '',
      usage_per_user: '',
      minimum_spend: '',
      maximum_spend: '',
      maximum_usage_limit: '',
      days: [],
      description: '',
      maximum_discount: '',
    },
    validate: {
      code: (value) => (value.length < 2 ? 'Please enter name' : null),
      // couponType === 'flat' ? amount : (value) => (value.length < 1 ? 'Please enter amount' : null), : maximum_discount: (value) => (value.length < 1 ? 'Please enter max discount' : null),
      start_date: (value) => (value.length < 1 ? 'Please select start date' : null),
      end_date: (value) => (value.length < 1 ? 'Please select end date' : null),
      minimum_spend: (value) => (value.length < 1 ? 'Please enter minimum purchase amount' : null),
      usage_per_user: (value) => (value.length < 1 ? 'Please enter user type' : null),
      maximum_spend: (value) => (value.length < 1 ? 'Please enter maximum purchase amount' : null),
      maximum_usage_limit: (value) => (value.length < 1 ? 'Please enter user limit' : null),
      days: (value) => (value.length < 1 ? 'Please select days' : null),
      description: (value) => (value.length < 1 ? 'Please enter discription' : null),
    },
  });
  const myDays = [
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
    { value: 'Sunday', label: 'Sunday' },
  ];

  const handleCreate = async (values) => {
    const formData = new FormData()
    formData.append('code', values?.code)
    if (couponType === 2) {
      formData.append('amount', values?.amount)
      formData.append('discount_type', couponType)
      formData.append('maximum_discount', values?.maximum_discount)
    }
    else {
      formData.append('amount', values?.amount)
    }
    formData.append('discount_type', couponType)
    formData.append('start_date', values?.start_date)
    formData.append('end_date', values?.end_date)
    formData.append('usage_per_user', values?.usage_per_user)
    formData.append('minimum_spend', values?.minimum_spend)
    formData.append('maximum_spend', values?.maximum_spend)
    formData.append('maximum_usage_limit', values?.maximum_usage_limit)
    formData.append('description', values?.description)
    values?.days.map((element, index) => (
      formData.append(`days[${index}]`, element)
    ))
    const result = await CreateDataService({ token, }, formData);

    if (result?.data?.status) {
      const resultAll = await dataListingService({ filterTerm: '', page: 1, countPerPage: 10, token });
      if (resultAll?.data?.status) {
        form.reset();
        dispatch(setDataList(resultAll?.data.data?.coupon_lists));
        dispatch(setFilteredList(resultAll?.data?.coupon_lists));
        dispatch(setTotalEntry(result?.data?.data?.pagination?.total));
        setLoading(false);
        SuccessToast(result?.data?.message)
        navigate('/admin/coupons');
        form.reset();
      } else {
        setLoading(false);
        ErrorToast(resultAll.data?.message);

      }
    }
  };
  const handleSelectType = (e) => {
    console.log('S', e)
    dispatch(setSelectedCoupon(e))
  };

  const hendleCancel = () => {
    navigate('/admin/coupons');
  };
  if (loading) {
    return (
      <Box>
        <Loading />
      </Box>
    );
  }


  return (
    <Box>
      <Card>
        <Grid>
          <Grid.Col span={12}><h3>Add Coupon</h3></Grid.Col>
          {/* {isNotification ? <Notification icon={<IconCheck size={18} />} color="teal" title="Notification">Coupon created successfully</Notification> : ''} */}
        </Grid>

        <form onSubmit={form.onSubmit((values) => handleCreate(values))}>
          <Grid>
            <Grid.Col span={6}>
              <TextInput label="Coupon Code" placeholder="Enter here" {...form.getInputProps('code')} />
            </Grid.Col>
            <Grid.Col span={6}>

              <Select

                label="Coupon Type"
                placeholder="Pick one"
                onChange={setSelectedCoupon}
                data={[
                  { value: 1, label: 'Fixed' },
                  { value: 2, label: 'Percentage' },
                ]}
              />
            </Grid.Col>

            {
              couponType === 2 ? 
              <Grid.Col span={6}>
                <TextInput required type="number" label="Max Discount" placeholder="Enter here" {...form.getInputProps('maximum_discount')} pattern="[0-9]+" maxlength="100" />
              </Grid.Col> 
                :
                <Grid.Col span={6}>
                  <TextInput required type="number" label="Discount Amount" placeholder="Enter here" {...form.getInputProps('amount')} />
                </Grid.Col>
            }
            {
              couponType === 2 ? 
              <Grid.Col span={6}>
              <TextInput min="1" max="100" required type="number" label="Discount in %" placeholder="Enter here" {...form.getInputProps('amount')} pattern="[0-9]+" maxlength="100" />
            </Grid.Col>
                :
                ''
            }
            <Grid.Col span={6}>
              <TextInput type="number" label="Limit Per User" placeholder="Enter here" {...form.getInputProps('usage_per_user')} />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput type="number" label="Max Usage Limit" placeholder="Enter here" {...form.getInputProps('maximum_usage_limit')} />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput type="number" label="Min Purchase Amount" placeholder="Enter here" {...form.getInputProps('minimum_spend')} />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput type="number" label="Max Purchase Amount" placeholder="Enter here" {...form.getInputProps('maximum_spend')} />
            </Grid.Col>
            <Grid.Col span={6}>
              <MultiSelect
                onChange={setDays}
                data={myDays}
                value={selectedDays}
                label="Select Days"
                placeholder="Pick days"
                {...form.getInputProps('days')}
              />
            </Grid.Col>

            <Grid.Col span={6}>
              {/* <DatePicker label="Expire Coupon" placeholder="Pick date" withAsterisk {...form.getInputProps('expire_coupon')} selected={startDate} onChange={(date:Date) => setStartDate(date)} /> */}
              <TextInput type="date" label="Start Date" placeholder="Pick date" withAsterisk {...form.getInputProps('start_date')} />
            </Grid.Col>
            <Grid.Col span={6}>
              {/* <DatePicker label="Expire Coupon" placeholder="Pick date" withAsterisk {...form.getInputProps('expire_coupon')} selected={startDate} onChange={(date:Date) => setStartDate(date)} /> */}
              <TextInput type="date"  min={moment().format('YYYY-MM-DD')} label="End Date" placeholder="Pick date" withAsterisk {...form.getInputProps('end_date')} />
            </Grid.Col>
            <Grid.Col span={12}>
              <Textarea label="Description" placeholder="Enter here" {...form.getInputProps('description')} />
            </Grid.Col>

            <Grid.Col span={12}>
              <Group position="left" mt="md">
                <Button color="dark" onClick={hendleCancel}>
                  Cancel
                </Button>
                <Button color="yellow" type="submit">Create</Button>
              </Group>
            </Grid.Col>



          </Grid>
        </form>


      </Card>
    </Box>
  );
};

export default Create;
