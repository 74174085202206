import React, { useState, useEffect } from 'react';
import { Card, Grid, Group, Button, Skeleton } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import { ImageDrop } from 'quill-image-drop-module';

// API-Service   
import { CreateDataServiceMechanic, dataListingServiceMechanic } from '../../../../service/aboutService';

// Utility-Service
import { getToken } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// Redux-Settings
import { selectStateMechanicData, setDataListMechanic, selectStateSingleData } from '../../../../redux/aboutSlice';

const Create = () => {
  const dispatch = useDispatch();
  const detail = useSelector(selectStateSingleData);
  const token = getToken();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)
  
  const { register, handleSubmit, setValue, watch, reset } = useForm({
    defaultValues: {
      about_us: '',
      privacy_policy: '',
      terms_and_conditions: '',
      about_us_malay: '',
      privacy_policy_malay: '',
      terms_and_conditions_malay: ''
    },
  });

  
  // Quill configuration (omitting for brevity)
  const fontSizeArr = ['32px', '40px', '45px'];
  const Font = ReactQuill.Quill.import('formats/font');
  const alignClass = ReactQuill.Quill.import('attributors/style/align');
  const backgroundClass = ReactQuill.Quill.import('attributors/style/background');
  const colorClass = ReactQuill.Quill.import('attributors/style/color');
  const directionClass = ReactQuill.Quill.import('attributors/style/direction');
  const fontClass = ReactQuill.Quill.import('attributors/style/font');
  const sizeClass = ReactQuill.Quill.import('attributors/style/size');

  ReactQuill.Quill.register(alignClass, true);
  ReactQuill.Quill.register(backgroundClass, true);
  ReactQuill.Quill.register(colorClass, true);
  ReactQuill.Quill.register(directionClass, true);
  ReactQuill.Quill.register(fontClass, true);
  ReactQuill.Quill.register(sizeClass, true);
  ReactQuill.Quill.register(Font, true);
  ReactQuill.Quill.register('modules/imageResize', ImageResize);
  ReactQuill.Quill.register('modules/imageDrop', ImageDrop);

  sizeClass.whitelist = fontSizeArr;
  alignClass.whitelist = ["right", "center", "justify"];
  fontClass.whitelist = ['poppins'];

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      [{ color: [] }, { background: [] }],
      [{ size: sizeClass.whitelist }],
      [{ font: [] }],
      ["link", "image", "video"],
      ["clean"]
    ],
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    }
  };

  const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'link', 'image', 'video'];

  // Fetch data on mount
  const fetchData = async () => {
    setLoading(true); // Start loading
    const result = await dataListingServiceMechanic({ token });
    if (result?.data?.status) {
      const resData = result?.data?.data?.mechanic_variables_list;
      if (resData.length > 0) {
        const aboutData = resData.find(item => item.name === 'about_us')?.value || '';
        const privacyData = resData.find(item => item.name === 'privacy_policy')?.value || '';
        const termsData = resData.find(item => item.name === 'terms_and_conditions')?.value || '';
        const aboutUsMalayData = resData.find(item => item.name === 'about_us_malay')?.value || '';
        const privacyMalayData = resData.find(item => item.name === 'privacy_policy_malay')?.value || '';
        const termsMalayData = resData.find(item => item.name === 'terms_and_conditions_malay')?.value || '';
        

        setValue('about_us', aboutData);
        setValue('privacy_policy', privacyData);
        setValue('terms_and_conditions', termsData);
        setValue('about_us_malay', aboutUsMalayData);
        setValue('privacy_policy_malay', privacyMalayData);
        setValue('terms_and_conditions_malay', termsMalayData);  
      }
      dispatch(setDataListMechanic(resData));
    }
    setLoading(false); // End loading
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async (data) => {
    const payload = {
      data: [
        {
          name: detail.name,
          value: data[detail.name],
        }
      ],
    };
  
    const result = await CreateDataServiceMechanic({ token }, payload);
    if (result?.data?.status) {
      SuccessToast(result?.data?.message);
      reset();
      navigate('/admin/mechanic/string/list');
    } else {
      ErrorToast(result?.data?.message);
    }
  };

  return (
    <Card>
      <Grid>
        <Grid.Col span={12}>
          <h2>Mechanic Strings</h2>
        </Grid.Col>
      </Grid>
      {loading ? ( 
        <Skeleton height={300} />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Col span={12}>
              <h3>
                {detail.name === 'about_us' ? 'About Us' :
                 detail.name === 'privacy_policy' ? 'Privacy Policy' :
                 detail.name === 'terms_and_conditions' ? 'Terms & Conditions' : 
                 detail.name === 'about_us_malay' ? 'AboutUs (Malay)' : 
                 detail.name === 'privacy_policy_malay' ? 'Privacy Policy (Malay)' : 
                 detail.name === 'terms_and_conditions_malay' ? 'Terms & Conditions (Malay)' : 'ContactUs'}
              </h3>
              <ReactQuill
                style={{ minHeight: 280 }}
                theme="snow"
                modules={modules}
                formats={formats}
                value={watch(detail.name)}
                onChange={(content) => setValue(detail.name, content)}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Group position="left" mt="md">
                <Button color="dark" onClick={() => navigate('/admin/mechanic/string/list')}>Cancel</Button>
                <Button color="yellow" type="submit">Save</Button>
              </Group>
            </Grid.Col>
          </Grid>
        </form>
      )}
    </Card>
  );
};

export default Create;
