// Component setvalue and having edit icon

import React, { useState } from 'react';
import { Menu, Button, ActionIcon, Box, Notification } from '@mantine/core';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconCheck } from '@tabler/icons';
import { FiEdit, FiMoreVertical } from "react-icons/fi";

// Utility Services
import { getToken } from '../../../../utils/AuthService';
import { SuccessToast } from '../../../../utils/utilities';

// API Services  
import { setSingleData } from '../../../../redux/aboutSlice';

const DeleteUpdate = ({ tableRecord }) => {
  const [isNotificationVisible, setNotificationVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = getToken();

  // Handle opening the update modal and dispatch the selected record
  const handleEdit = () => {
    navigate(`/admin/user/string`);
    dispatch(setSingleData(tableRecord));
  };

  return (
    <Box>
      <Menu control={<ActionIcon><FiMoreVertical size={18} /></ActionIcon>}>
        <Menu.Item onClick={handleEdit} icon={<FiEdit size={14} />}>
          Edit
        </Menu.Item>
      </Menu>

      {isNotificationVisible && (
        <Notification icon={<IconCheck size={18} />} color="teal" title="Success">
          Coupon created successfully
        </Notification>
      )}
    </Box>
  );
};

export default DeleteUpdate;
