import React, { useState, useEffect } from 'react';
import { Box, Card, Skeleton } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

// API-Service   
import { dataListingServiceMechanicWebpageAboutUs } from '../../../../service/aboutService';

// Utility-Service
import { ErrorToast } from '../../../../utils/utilities';

const ListingTable = () => {
  const [loading, setLoading] = useState(true);
  const [listing, setListing] = useState(null);
  const navigate = useNavigate();

  const countPerPage = 10;

  const fetchData = async (filterTerm = '', page = 1) => {
    setLoading(true); // Start loading
    try {
      const result = await dataListingServiceMechanicWebpageAboutUs({ filterTerm, page, countPerPage });
      if (result?.data?.status) {
        setListing(result.data.data);
      } else {
        ErrorToast(result);
      }
    } catch (error) {
      ErrorToast(error.message);
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    
    // Select elements with specific classes
    const sansElements = doc.body.querySelectorAll('.ql-font-sans');
    const monospaceElements = doc.body.querySelectorAll('.ql-font-monospace');

    // Apply styles to elements with specific classes
    sansElements.forEach(element => {
      element.style.fontFamily = 'sans-serif';
    });

    monospaceElements.forEach(element => {
      element.style.fontFamily = 'monospace';
    });

    return doc.body.innerHTML;
  };

  return (
    <Card>
      <Box>
        {loading ? (
          // Skeleton loader while data is loading
          <>
            <Skeleton height={300} mb="xs" />
            
          </>
        ) : (
          listing?.value ? (
            <div>
              <div className='d-flex'>
                <div style={{ marginRight: '10px' }}>
                  <h4>About Us</h4>
                </div>
              </div>
              <div dangerouslySetInnerHTML={{ __html: renderHTML(listing.value) }} />
            </div>
          ) : (
            'No data found'
          )
        )}
      </Box>
    </Card>
  );
};

export default ListingTable;
