import React, { useState, useEffect, useCallback } from 'react';
import { TextInput, Text, Textarea, MultiSelect, Card, Grid, Calendar, Box, Button, Modal, Group, Notification } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IconCheck, IconX } from '@tabler/icons';
import moment from 'moment';

// ** Third Party Components
import Select from 'react-select'

import Loading from '../../../../components/Loading/Loading';

// API-Service   
import { getSingleDataService, dataListingService, UpdateSingleDataService } from '../../../../service/couponService';

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// Redux-Settings
import { setDataList, setTotalEntry, setFilteredList, selectStateSingleData, setSingleData } from '../../../../redux/couponSlice';

const Update = ({ tableRecord }) => {
    const [startDate, setStartDate] = useState(new Date());
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [couponType, setSelectedCoupon] = useState('');
    const [couponsTypes, setSelectedCouponTypes] = useState({});
    const [selectedDays, setDays] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);
    const token = getToken();
    const navigate = useNavigate();
    const myData = useSelector(selectStateSingleData);
    const [formValue, setFromValue] = useState('');
    const [inputError, setInputError] = useState(false);
    const { id } = useParams();
    if (selectedDays.length > 0 && couponsTypes?.value) {
        console.log('selectedDays', selectedDays)
        console.log('couponsTypes', couponsTypes)
    }
    const form = useForm({
        initialValues: {
            code: '',
            discount_type: null,
            amount: '',
            discount_type_name: '',
            start_date: '',
            end_date: '',
            usage_per_user: '',
            minimum_spend: '',
            maximum_spend: '',
            maximum_usage_limit: '',
            days: [],
            description: '',
        },
        // validate: {
        //     code: (value) => (value.length < 2 ? 'Please enter name' : null),
        //     couponType: (value) => (value.length < 2 ? 'Please enter type' : null),
        //     discount: (value) => (value.length < 2 ? 'Please enter discount' : null),
        //     maximum_discount: (value) => (value.length < 2 ? 'Please enter max mount' : null),
        //     min_purchase: (value) => (value.length < 2 ? 'Please enter min purchase amount' : null),
        //     limit_per_user: (value) => (value.length < 1 ? 'Please enter limit per user' : null),
        //     user_type: (value) => (value.length < 2 ? 'Please enter user type' : null),
        //     expire_coupon: (value) => (value.length < 2 ? 'Please select expire date' : null),
        // },
    });
    // if (formValue?.coupon_type) {
    //     setSelectedCouponTypes({ value: formValue?.coupon_type, label: formValue?.coupon_type_name });
    //     console.log('condition', couponsTypes)
    // }

    const allCouponTypes = [
        { value: 1, label: 'Fixed' },
        { value: 2, label: 'Percentage' },
    ]

    const myDays = [
        { value: 'Monday', label: 'Monday' },
        { value: 'Tuesday', label: 'Tuesday' },
        { value: 'Wednesday', label: 'Wednesday' },
        { value: 'Thursday', label: 'Thursday' },
        { value: 'Friday', label: 'Friday' },
        { value: 'Saturday', label: 'Saturday' },
        { value: 'Sunday', label: 'Sunday' },
    ];

    const getSingleData = async () => {
        const result = await getSingleDataService({ id, token });
        if (result?.data?.status) {
            setLoading(false);
            setSelectedCouponTypes({ value: result?.data?.data?.coupon_type, label: result?.data?.data?.coupon_type_name });
            // if (result?.data?.data?.coupon_type) {
            //     setSelectedCouponTypes({ value: result?.data?.data?.coupon_type, label: result?.data?.data?.coupon_type_name });
            // }
            setFromValue(result?.data?.data)
            if (result?.data?.data?.days.length > 0) {
                setDays(result?.data?.data?.days.map((item) => ({ value: item, label: item })));
            }
            // if (result?.data?.data?.coupon_type === 2) {
            //     setSelectedCouponTypes({ value: 2, label: 'Percentage' });
            // }
            // else {
            //     setSelectedCouponTypes({ value: 1, label: 'Fixed' });
            // }
        } else {
            setLoading(false);
            ErrorToast(result);
        }
    };
    useEffect(() => {
        getSingleData();
        const timeoutId = setTimeout(() => 3000);
        return function cleanup() {
            clearTimeout(timeoutId);
        };
    }, []);

    const handleCodeChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            code: e.target.value,
        }));
    };
    const handleMaxDisChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            maximum_discount: e.target.value,
        }));
    };

    const handleAmountChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            amount: e.target.value,
        }));
    };
    const handleUserLimitChange = (e) => {
        setFromValue((prevState) => ({
            ...prevState,
            usage_per_user: e.target.value,
        }));
    };

    const handleMaxChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            maximum_spend: e.target.value,
        }));
    };
    const handleMinChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            minimum_spend: e.target.value,
        }));
    };
    const handleDaysChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            days: e.target.value,
        }));
    };
    const handleLimitChange = (e) => {
        setFromValue((prevState) => ({
            ...prevState,
            limit_per_user: e.target.value,
        }));
    };
    const handleUserTpeChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            user_type: e.target.value,
        }));
    };
    const handleStartDateChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            start_date: e.target.value,
        }));
    };
    const handleEndDateChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            end_date: e.target.value,
        }));
    };
    const handleDescriptionChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            description: e.target.value,
        }));
    };

    const handleUpdate = async (e) => {
        // e.preventDefault();
        const formData = new FormData()
        formData.append('id', formValue?.id)
        formData.append('code', formValue?.code)
        if (formValue.coupon_type === 2) {
            formData.append('amount', formValue?.amount)
            // formData.append('discount_type', couponsTypes?.value)
            formData.append('maximum_discount', formValue?.maximum_discount)
        }
        else {
            formData.append('amount', formValue?.amount)
        }
        formData.append('discount_type', couponsTypes?.value)
        formData.append('start_date', formValue?.start_date)
        formData.append('end_date', formValue?.end_date)
        formData.append('usage_per_user', formValue?.usage_per_user)
        formData.append('minimum_spend', formValue?.minimum_spend)
        formData.append('maximum_spend', formValue?.maximum_spend)
        formData.append('maximum_usage_limit', formValue?.maximum_usage_limit)
        formData.append('description', formValue?.description)
        selectedDays.map((element, index) => (
            formData.append(`days[${index}]`, element.value)
        ))
        formData.append('_method', 'PUT')

        const result = await UpdateSingleDataService({ token, id }, formData);
        if (result?.data?.status) {
            const resultAll = await dataListingService({ filterTerm: '', page: 1, countPerPage: 10, token });
            if (resultAll?.data?.data) {
                form.reset();
                dispatch(setDataList(resultAll?.data?.data));
                dispatch(setFilteredList(resultAll?.data?.data));
                dispatch(setTotalEntry(result?.data?.data?.pagination?.total));
                setLoading(false);
                SuccessToast(result?.data?.message)
                navigate('/admin/coupons');
                form.reset();
            } else {
                setLoading(false);
                ErrorToast(resultAll.data);

            }
        }
    };


    const hendleCancel = () => {
        setInputError(false);
        form.reset();
        navigate('/admin/coupons');
    };

    const showData = () => {
        if (selectedDays.length > 0) {
            <>
                <Grid.Col span={6}>
                    <Text for="Days">Select Days </Text>
                    {/* {selectedDays.length > 0 ? selectedDays : 'CNG'} */}
                    <Select
                        isMulti
                        required
                        label="Select Days"
                        placeholder="Pick days"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        defaultValue={selectedDays || ''}
                        options={myDays}
                        onChange={setDays}
                    />
                    {/* {selectedDays.length > 0 ?
                                 <Select
                                     isMulti
                                     required
                                     label="Select Days"
                                     placeholder="Pick days"
                                     className="basic-multi-select"
                                     classNamePrefix="select"
                                     defaultValue={selectedDays}
                                     options={myDays}
                                     onChange={setDays}
                                 />
                                 : <Select
                                     isMulti
                                     required
                                     label="Select Days"
                                     placeholder="Pick days"
                                     className="basic-multi-select"
                                     classNamePrefix="select"
                                     defaultValue={selectedDays}
                                     options={myDays}
                                     onChange={setDays}
                                 />
                             } */}
                    {inputError && <Text style={{ color: '#EE7297' }}>Please select days </Text>}

                </Grid.Col>
            </>
        } else {
            <>
                <Grid.Col span={6}>
                    <Text for="Days">Select Days </Text>
                    <Select
                        isMulti
                        required
                        label="Select Days"
                        placeholder="Pick days"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        defaultValue={selectedDays}
                        options={myDays}
                        onChange={setDays}
                    />
                    {inputError && <Text style={{ color: '#EE7297' }}>Please select days </Text>}
                </Grid.Col>
            </>
        }
    }

    if (loading) {
        return (
            <Box>
                <Loading />
            </Box>
        );
    }


    return (
        <Box>
            <Card>
                <Grid>
                    <Grid.Col span={12}><h3>Update Coupon </h3></Grid.Col>
                </Grid>

                <form onSubmit={form.onSubmit((values) => handleUpdate(values))}>
                    <Grid>
                        <Grid.Col span={6}>
                            <TextInput required label="Coupon Code" placeholder="Enter here" value={formValue?.code || ""} onChange={handleCodeChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please enter coupon code</Text>}
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Text for="couponType">Select Coupon Type</Text>
                            <Select
                                // theme={selectThemeColors}
                                isDisabled={isDisabled}
                                className='react-select'
                                classNamePrefix='select'
                                // defaultValue={allCouponTypes[0]}
                                value={couponsTypes.value? couponsTypes : ''}
                                name='clear'
                                options={allCouponTypes}
                                // isClearable
                                placeholder='Types'
                                onChange={setSelectedCouponTypes}
                            />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please select coupon type</Text>}
                        </Grid.Col>
                        {
                            couponsTypes?.value === 2 ?
                                <Grid.Col span={6}>
                                    <TextInput required type="number" label="Max Discount" placeholder="Enter here" value={formValue?.maximum_discount || ""} onChange={handleMaxDisChange} pattern="[0-9]+" maxlength="100" />
                                </Grid.Col>
                                :
                                <Grid.Col span={6}>
                                    <TextInput required type="number" label="Discount Amount" placeholder="Enter here" value={formValue?.amount || ""} onChange={handleAmountChange} />
                                </Grid.Col>
                        }
                        {
                            couponsTypes?.value === 2 ?
                                <Grid.Col span={6}>
                                    <TextInput min="1" max="100" required type="number" label="Discount in %" placeholder="Enter here" value={formValue?.amount || ""} onChange={handleAmountChange} pattern="[0-9]+" maxlength="100" />
                                </Grid.Col>
                                :
                                ''
                        }
                        <Grid.Col span={6}>
                            <TextInput type="number" label="Limit Per User" placeholder="Enter here" value={formValue?.usage_per_user || ""} onChange={handleUserLimitChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please enter limit per user </Text>}
                        </Grid.Col>
                        {/* <Grid.Col span={6}>
                            <TextInput type="number" label="Max Discount" placeholder="Enter here" value={formValue?.maximum_discount || ""} onChange={handleMaxDisChange} />
                        </Grid.Col> */}
                        <Grid.Col span={6}>
                            <TextInput required type="number" label="Min Purchase Amount" placeholder="Enter here" value={formValue?.minimum_spend || ""} onChange={handleMinChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please enter minimum purchase amount </Text>}
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput required type="number" label="Max Purchase Amount" placeholder="Enter here" value={formValue?.maximum_spend || ""} onChange={handleMaxChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please enter maximum purchase amount </Text>}
                        </Grid.Col>

                        {/* {showData()} */}
                        <Grid.Col span={6}>
                            <Text for="Days">Select Days </Text>
                            <Select
                                isMulti
                                required
                                label="Select Days"
                                placeholder="Pick days"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={selectedDays || ''}
                                options={myDays}
                                onChange={setDays}
                            />
                            {/* {selectedDays.length > 0 ?
                                 <Select
                                     isMulti
                                     required
                                     label="Select Days"
                                     placeholder="Pick days"
                                     className="basic-multi-select"
                                     classNamePrefix="select"
                                     defaultValue={selectedDays}
                                     options={myDays}
                                     onChange={setDays}
                                 />
                                 : <Select
                                     isMulti
                                     required
                                     label="Select Days"
                                     placeholder="Pick days"
                                     className="basic-multi-select"
                                     classNamePrefix="select"
                                     defaultValue={selectedDays}
                                     options={myDays}
                                     onChange={setDays}
                                 />
                             } */}
                            {inputError && <Text style={{ color: '#EE7297' }}>Please select days </Text>}

                        </Grid.Col>
                        <Grid.Col span={6}>
                            {/* <DatePicker label="Expire Coupon" placeholder="Pick date" withAsterisk {...form.getInputProps('expire_coupon')} selected={startDate} onChange={(date:Date) => setStartDate(date)} /> */}
                            <TextInput required type="date" label="Start Date" placeholder="Pick date" withAsterisk value={formValue?.start_date || ""} onChange={handleStartDateChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please select start date </Text>}
                        </Grid.Col>
                        <Grid.Col span={6}>
                            {/* <DatePicker label="Expire Coupon" placeholder="Pick date" withAsterisk {...form.getInputProps('expire_coupon')} selected={startDate} onChange={(date:Date) => setStartDate(date)} /> */}
                            <TextInput required type="date"  min={moment().format('YYYY-MM-DD')} label="End Date" placeholder="Pick date" withAsterisk value={formValue?.end_date || ""} onChange={handleEndDateChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please select expiry date  </Text>}
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Textarea required label="Description" placeholder="Enter here" value={formValue?.description || ""} onChange={handleDescriptionChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please enter description </Text>}
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Group position="left" mt="md">
                                <Button color="dark" onClick={hendleCancel}>
                                    Cancel
                                </Button>
                                <Button color="yellow" type="submit">Save</Button>
                            </Group>
                        </Grid.Col>



                    </Grid>
                </form>


            </Card>
        </Box>
    );
};

export default Update;
