import React, { useState, useEffect } from 'react';
import { Menu, Button, Textarea, Modal, Group, Box, TextInput, ActionIcon } from '@mantine/core';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FiEdit, FiTrash, FiEye, FiMoreVertical, } from "react-icons/fi";

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// API-Service  
import { customerListService, customerCreateService, customerRemoveService } from '../../../../service/customer.Service';
import { PlanListingServiceCustomer, CreateDataServiceDistance } from '../../../../service/planService';

// Redux-Settustomerings
import { setCustomerList, setTotalEntry, setFilteredList, setMechanicList } from '../../../../redux/customerSlice';

const DeleteUpdate = ({ tableRecord }) => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  // const [mechanicData, setMechanicList] = useState([]);

  const [formValue, setFromValue] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = getToken();

  // All Record-update Controller
  const hendleUpdateModal = () => {
    setIsUpdateModal(true);
  };

  useEffect(() => {
    setFromValue(tableRecord);
  }, [tableRecord]);


  const handleTitleChange = (e) => {
    setFromValue((prevState) => ({
      ...prevState,
      name: e.target.value,
    }));
  };

  const handleDescriptionChange = (e) => {
    setFromValue((prevState) => ({
      ...prevState,
      value: e.target.value,
    }));
  };

  const handleValueChange = (e) => {
    setFromValue((prevState) => ({
      ...prevState,
      value: e.target.value,
    }));
  };

  const hendleDeleteModal = () => {
    setIsDeleteModal(true);
  };

  const hendleCancel = () => {
    setIsDeleteModal(false);
    setIsUpdateModal(false);
  };

  const hendleDelete = async () => {
    const result = await customerRemoveService({
      id: tableRecord?.id,
      token,
    });
    setIsDeleteModal(false);
    if (result?.data?.status) {
      const resultAll = await customerListService({ filterTerm: 'Active', page: 1, countPerPage: 10, token });
      if (resultAll?.data?.status) {
        dispatch(setCustomerList(resultAll?.data?.data?.customer_variables_list));
        dispatch(setFilteredList(resultAll?.data?.data?.customer_variables_list));
        dispatch(setTotalEntry(result?.data?.data?.customer_variables_list));
        SuccessToast(resultAll?.data?.data?.message); 
      }
    } else {
      ErrorToast("Something went to wrong");
    }
    // setIsDeleteModal(false)
  };


  const mechanicDataFetch = async () => {
    const result = await PlanListingServiceCustomer({ token });
    if (result?.data?.status) {
      dispatch(setMechanicList(result?.data?.data?.mechanic_variables_list));
    } else {
      ErrorToast(result);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const data = [];
    const myObject = {
      name: formValue?.name === 'mech_minimum_withdraw' ? 'minimum_withdraw' : formValue?.name,
      value: formValue?.value,
    }
    data.push(myObject);
    // console.log(data)
    let resultUpdate = ''
    if (formValue?.name === 'mechanic_max_distance') {
      resultUpdate = await CreateDataServiceDistance(data, { token });
    } else if (formValue?.name === 'extra_payment_commission_breakdown') {
      resultUpdate = await CreateDataServiceDistance(data, { token });
    } else if (formValue?.name === 'extra_payment_commission_service') {
      resultUpdate = await CreateDataServiceDistance(data, { token });
    } else if (formValue?.name === 'mech_minimum_withdraw') {
      resultUpdate = await CreateDataServiceDistance(data, { token });
    } else {
      resultUpdate = await customerCreateService(data, { token });
    }
 
    if (resultUpdate?.data?.status) {
      const resultAll = await customerListService({ filterTerm: 'Active', page: 1, countPerPage: 10, token });
      if (resultAll?.data?.status) {
        mechanicDataFetch()
        dispatch(setCustomerList(resultAll?.data?.data?.customer_variables_list));
        dispatch(setFilteredList(resultAll?.data?.data?.customer_variables_list));
        dispatch(setTotalEntry(resultAll?.data?.data?.customer_variables_list.length));
        SuccessToast('Updated successfully');
        setIsUpdateModal(false);
      }
      else {  
        ErrorToast("Something went to wrong");
      }
    } else {
      ErrorToast("Something went to wrong");
    }
    setIsUpdateModal(false);
  };

  return (
    <Box>
      <Menu control={<ActionIcon><FiMoreVertical size={18} /></ActionIcon>}>
        <Menu.Item onClick={hendleUpdateModal} icon={<FiEdit size={14} />}>
          Edit
        </Menu.Item>
        {/* <Menu.Item
          onClick={hendleDeleteModal}
          icon={<FiTrash size={14} />}>
          Delete
        </Menu.Item> */}
      </Menu>
      <Modal opened={isDeleteModal} onClose={() => setIsDeleteModal(false)} title="Please Confirm" centered>
        <Group position="center">
          <Button variant="outline" onClick={hendleCancel}>
            Cancel
          </Button>
          <Button onClick={hendleDelete}>Delete</Button>
        </Group>
      </Modal>
      <Modal opened={isUpdateModal} onClose={() => setIsUpdateModal(false)} title="Update" centered>
        <form onSubmit={(values) => handleUpdate(values)}>
          <TextInput
            required
            label="Title"
            value={formValue?.name || ''}
            disabled
            onChange={handleTitleChange} />
          {
            formValue?.name === 'mechanic_max_distance' ?
              <TextInput
                required
                type="number"
                style={{ marginTop: 15 }}
                label="KM"
                value={formValue?.value || ''}
                onChange={handleValueChange}
              />
              : <TextInput
                required
                type="number"
                style={{ marginTop: 15 }}
                label={formValue?.name === 'extra_payment_commission_service' || formValue?.name === 'extra_payment_commission_breakdown' ? 'Percentage' : 'Charge'}
                value={formValue?.value || ''}
                onChange={handleValueChange}
              />
          }

          <Group position="center" mt="md">
            <Button color="dark" onClick={hendleCancel}>
              Cancel
            </Button>
            <Button color="yellow" type="submit">Update</Button>
          </Group>
        </form>
      </Modal>
    </Box>
  );
};

export default DeleteUpdate;
