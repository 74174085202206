import React, { useState, useEffect } from 'react';
import { Menu, Button, Image, Textarea, Modal, Group, Box, TextInput, Notification, ActionIcon, Loader } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconCheck, IconX } from '@tabler/icons';
import { FiCheck, FiDelete, FiEye, FiMoreVertical, FiDownload } from "react-icons/fi";
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import ReactDOM from 'react-dom';
import Invoice from './mechanicDownloadFile'


// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// API-Service  
import { mechanicWithdrawalListingService, mechanicWithdrawalUpdateStatusService, downloadMechFile } from '../../../../service/withdrawalService';

// Redux-Settings
import {
  setUserWithdrawalsList,
  setMechanicWithdrawalsList,
  setMechanicTotalEntry,
} from '../../../../redux/withdrawalSlice';

const DeleteUpdate = ({ tableRecord }) => {
  const [isAcceptModal, setIsAcceptModal] = useState(false);
  const [isRejectModal, setIsRejectModal] = useState(false);
  const [isDownloadModal, setIsDownloadModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formValue, setFromValue] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = getToken();

  useEffect(() => {
    setFromValue(tableRecord);
  }, [tableRecord]);

  const hendleDeleteModal = () => {
    setIsAcceptModal(true);
  };

  const hendleRejectModal = () => {
    setIsRejectModal(true);
  };

  const handleDownloadModal = () => {
    setIsDownloadModal(true);
  };

  const hendleCancel = () => {
    setIsAcceptModal(false);
  };
  const hendleRejectCancel = () => {
    setIsRejectModal(false);
  };

  const handleDownloadCancel = () => {
    setIsDownloadModal(false);
  };

  const withdrawalAccept = async (rowRecord) => {
    try {
      const statusUpdateresult = await mechanicWithdrawalUpdateStatusService({ id: tableRecord?.id, is_approved: 1, token });
      if (statusUpdateresult?.data?.status) {
        setIsAcceptModal(false);
        SuccessToast(statusUpdateresult.data.message);
        const resultAll = await mechanicWithdrawalListingService({ filterTerm: 'Active', page: 1, countPerPage: 10, token });
        if (resultAll?.data?.status) {
          dispatch(setMechanicWithdrawalsList(resultAll?.data?.data?.withdraw_lists));
          dispatch(setMechanicTotalEntry(resultAll?.data?.data?.pagination?.total));
          navigate('/admin/withdrawals');
        } else {
          ErrorToast(resultAll.data.message);
        }
      } else if (statusUpdateresult?.response?.status === 422) {
        console.log('harsh', statusUpdateresult)
        ErrorToast(statusUpdateresult?.response?.data?.message || 'An error occurred');
      } else {
        ErrorToast(statusUpdateresult?.data?.message || 'An error occurred');
      }
    } catch (error) {
      ErrorToast('An unexpected error occurred');
    }
  };
  


  const withdrawalReject = async (rowRecord) => {
    const statusUpdateresult = await mechanicWithdrawalUpdateStatusService({ id: tableRecord?.id, is_approved: 0, token });
    if (statusUpdateresult?.data?.status) {
      setIsRejectModal(false);
      SuccessToast(statusUpdateresult.data.message)
      const resultAll = await mechanicWithdrawalListingService({ filterTerm: 'Active', page: 1, countPerPage: 10, token });
      if (resultAll?.data?.status) {
        dispatch(setUserWithdrawalsList(resultAll?.data?.data?.withdraw_lists));
        dispatch(setMechanicTotalEntry(resultAll?.data?.data?.pagination?.total));
        navigate('/admin/withdrawals')
        // SuccessToast(resultAll.data.message)
      } else {
        ErrorToast(resultAll.data.message);
      }
    } else {
      ErrorToast(statusUpdateresult);
    }
  };
  
  const handleDownloadClick = async (id) => {
    try {
      setLoading(true); 
      const response = await downloadMechFile({ id: tableRecord?.id, token })
      const data = response?.data?.data
      const tempElement = document.createElement('div')
      tempElement.style.position = 'absolute'
      tempElement.style.top = '-9999px'
      document.body.appendChild(tempElement)
  
      ReactDOM.render(<Invoice data={data} />, tempElement)
  
      const canvas = await html2canvas(tempElement, { scale: 2 })
      const imgData = canvas.toDataURL('image/png')
      
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('p', 'mm', 'a4')
      pdf.addImage(imgData, 'PNG', 10, 10, 190, 0)
     
      const pdfFileName = `Invoice-${data?.mechanic?.company_name}-${data?.id}.pdf`;
      pdf.save(pdfFileName);
  
      document.body.removeChild(tempElement)
      setIsDownloadModal(false)
      setLoading(false)
    } catch (error) {
      ErrorToast('Error while downloading')
      console.error('Download error', error)
      setLoading(false)
    }
  };

  return (
    <Box>

      <Menu control={<ActionIcon><FiMoreVertical size={18} /></ActionIcon>}>
     
         <Menu.Item
          onClick={handleDownloadModal}
          icon={<FiDownload size={14} />}>
          Download
        </Menu.Item>
      </Menu>
      <Modal opened={isAcceptModal} onClose={() => setIsAcceptModal(false)} title="Do you want to accept this" centered>
        <Group position="center">
          <Button color="dark" onClick={hendleCancel}>
            Cancel
          </Button>
          <Button color="yellow" onClick={() => withdrawalAccept()}>Yes</Button>
        </Group>
      </Modal>

      <Modal opened={isRejectModal} onClose={() => setIsRejectModal(false)} title="Do you want to reject this" centered>
        <Group position="center">
          <Button color="dark" onClick={hendleRejectCancel}>
            Cancel
          </Button>
          <Button color="yellow" onClick={() => withdrawalReject()}>Yes</Button>
        </Group>
      </Modal>

      <Modal opened={isDownloadModal} onClose={() => setIsDownloadModal(false)} title="Do you want to download this" centered>
        <Group position="center">
          <Button color="dark" onClick={handleDownloadCancel}>
            Cancel
          </Button>
          <Button color="yellow" onClick={handleDownloadClick} disabled={loading}>
          {loading ? <Loader size="sm" color="white" /> : 'Yes'}
        </Button>
        </Group>
      </Modal>
    </Box>
  );
};

export default DeleteUpdate;