import React, { useState, useEffect } from 'react';
import { Box, Image, Switch } from '@mantine/core';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import Loading from '../../../../components/Loading/Loading'; 

// API-Service   
import { dataListingServiceUser } from '../../../../service/aboutService';

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// Redux-Settings
import { setDataList, selectStateData, } from '../../../../redux/aboutSlice';

import DeleteUpdate from './UpdateUserString'
import UserMessage from './UserStringDetail';
    
const ListingTable = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const Listing = useSelector(selectStateData);

  const token = getToken();
  const navigate = useNavigate();

  const [pageCount, setPageCount] = useState(1);
  const countPerPage = 10;

  const fetchData = async (filterTerm, page) => {
    const result = await dataListingServiceUser({ filterTerm, page, countPerPage, token })
    if (result?.data?.status) {
      setLoading(false)
      const filteredVariables = result?.data?.data?.customer_variables_list.filter(variable =>
        variable.name === "about_us" || variable.name === "terms_and_conditions" || variable.name === "privacy_policy"
        || variable.name === "about_us_malay" || variable.name === "privacy_policy_malay"  ||
        variable.name === "terms_and_conditions_malay"  
      )
       
      dispatch(setDataList(filteredVariables));
    } else {
      setLoading(false)
      ErrorToast(result)
    }
  } 
  
  
  useEffect(() => {
    setLoading(true);
    fetchData();
    const timeoutId = setTimeout(() => 3000);
    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, []);

  const columns = [

    {
      name: 'Title',
      selector: (row) => {

        let showData = ''
        if (row.name === 'about_us') {
          showData = 'About Us'
        }

        if (row.name === 'privacy_policy') {
          showData = 'Privacy policy'
        }

        if (row.name === 'terms_and_conditions') {
          showData = 'Terms and Conditions'
        }

        if (row.name === 'about_us_malay') {
          showData = 'AboutUs (Malay)'
        }
        if (row.name === 'privacy_policy_malay') {
          showData = 'Privacy policy (Malay)'
        }
        if (row.name === 'terms_and_conditions_malay') {
          showData = 'Terms and Conditions (Malay)'
        }
        
        return showData
      },
      maxWidth: '450px',
    },
      { 
        name: 'Description',
        selector: (row) => <UserMessage tableRecord={row} />,
        sortable: true, 
        minWidth: '120px'
      },
 
    {
      name: 'Created Date',
      selector: (row) => moment(row.created_at).format("DD-MM-YYYY"),
      maxWidth: '300px', 
    },
    { 
      name: 'Actions',
      selector: (row) => <DeleteUpdate tableRecord={row} />,
      sortable: true,
    },
  ];

  if (Listing?.length === 0 && loading) {
    return (
      <Box
      sx={() => ({
        margin: '0 auto',
        minHeight: 440,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      })}
    >
      <span className="visually-hidden"><Loading /></span>
    </Box>
    );
  }

  return (
    <Box>
      <Box className="rui-filemanager-content">
        <Box className="table-responsive-lg mb-20 inner-content-fix">
          <DataTable
            className="rui-datatable rui-filemanager-table table mb-20 mt-10"
            columns={columns}
            data={Listing}
            key={Listing?.id}
            highlightOnHover
            responsive
           // pagination
            paginationServer
            paginationTotalRows=''
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            onChangePage={(page) => setPageCount(page)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ListingTable;
