// ** Displaying Content on Modal
import React, { useState, useEffect } from 'react';
import { Button, Modal, Col, Box, Grid, Card, ScrollArea } from '@mantine/core';

// Assuming renderHTML is a function that processes your HTML content
const renderHTML = html => html; 

const UserMessage = ({ tableRecord }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Box>
      {/* Button to trigger modal */}
      <Button onClick={() => setIsModalOpen(true)}>View</Button>

      {/* Modal displaying the content */}
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Description"
        centered
        size="80%"
        scrollAreaComponent={ScrollArea}
        style={{ maxHeight: '600px' }}
      >
        <Card>
          <Grid style={{ background: '#EFF4FF', padding: '12px' }}>
            <Col>
              {/* Render HTML content safely */}
              <div dangerouslySetInnerHTML={{ __html: renderHTML(tableRecord?.value) }} />
            </Col>
          </Grid>
        </Card>
      </Modal>
    </Box>
  );
};

export default UserMessage;
