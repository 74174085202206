import React, { useState, useCallback } from 'react';
import { TextInput, Textarea, Box, Button, Modal, Group, FileInput, Input } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../../../components/Loading/Loading';

// API-Service   
import { bankListSevice, addBankService } from '../../../../service/bankService';

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

import FileUpload from './FileUploader';

// Redux-Settings
import { setBankList, setTotalEntry, setFilteredList } from '../../../../redux/bankSlice';

const Create = () => {
  const [isCreateModal, setCreateModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const token = getToken();
  const navigate = useNavigate();
  const [file, setFile] = useState({});
  const [showImage, setImage] = useState();

  const form = useForm({
    initialValues: {
      name: '',
      image: '',
    },
    validate: {
      name: (value) => (value.length < 2 ? 'Please enter name' : null),
      // image: (value) => (value.length < 2 ? 'Please select image' : null),
    },
  });

  const changeHandler = (event) => {
     setFile(event.target.files[0])
     setImage(URL.createObjectURL(file))
  };

  const handleCreate = async (values) => {
    const formData = new FormData()
    formData.append('image', file)
    formData.append('name', values?.name)
    formData.append('swift_code', values?.swift_code)
    formData.append('token', token)
    setLoading(true);
    const result = await addBankService({ token, }, formData);

    if (result?.data?.status) {
      const resultAll = await bankListSevice({ filterTerm: '', page: 1, countPerPage: 10, token });
      if (resultAll?.data?.status) {
        dispatch(setBankList(resultAll?.data?.data?.bank_list));
        dispatch(setTotalEntry(result?.data?.data?.data?.pagination?.total));
        setLoading(false);
        setCreateModal(false);
        form.reset();
        SuccessToast('Bank created successfully')
      } else {
        setLoading(false);
        ErrorToast(resultAll);
      }
    } 
  };

  const hendleCancel = () => {
    setCreateModal(false);
    form.reset();
  };

  // if (loading) {
  //   return (
  //     <Box>
  //       <Loading />
  //     </Box>
  //   );
  // }

  return (
    <Box>
      <Button color="yellow" onClick={() => setCreateModal(true)}>Add Bank</Button>
      <Modal opened={isCreateModal} onClose={() => {
          setCreateModal(false)
          form.reset()
        }} title="Add Bank" centered>
        <form onSubmit={form.onSubmit((values) => handleCreate(values))}>
          <TextInput label="Bank Name" placeholder="Enter name here" {...form.getInputProps('name')} />
          <TextInput label="Swift Code" placeholder="Enter here" {...form.getInputProps('swift_code')} />
          {/* <div className="upload-image">
          <img src={showImage} alt={file.name} />
          </div> */}
          <div style={{ marginTop: 15 }}>
            <TextInput label="Select Photo" type="file" required name="file" accept="image/png, image/jpg, image/jpeg" onChange={changeHandler} />
          </div>

          <Group position="center" mt="md">
            <Button color="dark" onClick={hendleCancel}>
              Cancel
            </Button>
            <Button color="yellow" type="submit">Create</Button>
          </Group>
        </form>
      </Modal>
    </Box>
  );
};

export default Create;
