import React, { useState, useCallback } from 'react';
import { TextInput, Textarea, Box, Button, Modal, Group, FileInput, Input } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../../../components/Loading/Loading';

// API-Service   
import { mechanicListService, mechanicCreateService } from '../../../../service/mechanic.service';

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

import FileUpload from './FileUploader';

// Redux-Settings
import { setMechanicServiceList, setTotalEntry, setFilteredList } from '../../../../redux/mechanicServiceSlice';

const Create = () => {
  const [isCreateModal, setCreateModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const token = getToken();
  const navigate = useNavigate();
  const [file, setFile] = useState({});
  const [showImage, setImage] = useState();

  const form = useForm({
    initialValues: {
      name: '',
      image: '',
    },
    validate: {
      name: (value) => (value.length < 2 ? 'Please enter name' : null),
      // image: (value) => (value.length < 2 ? 'Please select image' : null),
    },
  });

  const changeHandler = (event) => {
    const myFile = event.target.files[0]
    setFile(myFile)
    // setImage(URL.createObjectURL(file))
  };

  const changeSelectedFile = (event) => {
    const myFile = event.target.files[0]
    setSelectedFile(myFile)
    // setImage(URL.createObjectURL(file))
  };

  const handleCreate = async (values) => {
    if (file && selectedFile) {
      console.log('azadFile')
      const formData = new FormData()
      formData.append('image', file)
      formData.append('selected_image', selectedFile)
      formData.append('name', values?.name)
      formData.append('token', token)
      setLoading(true);
      const result = await mechanicCreateService({ token, }, formData);

      if (result?.data?.status) {
        const resultAll = await mechanicListService({ filterTerm: '', page: 1, countPerPage: 10, token });
        if (resultAll?.data?.status) {
          dispatch(setMechanicServiceList(resultAll?.data?.data?.mechanic_services_list));
          dispatch(setFilteredList(resultAll?.data?.data?.mechanic_services_list));
          dispatch(setTotalEntry(result?.data?.data?.data?.mechanic_services_list.length));
          setLoading(false);
          setCreateModal(false);
          form.reset();
          SuccessToast(result?.data?.data?.message);
          navigate('/admin/services');
        } else {
          setLoading(false);
          console.log('azadError')
          ErrorToast(result?.data?.data?.message);
        }
      }

    } else {
      ErrorToast('Please upload same image');

    }

  };

  const hendleCancel = () => {
    setCreateModal(false);
  };

  // if (loading) {
  //   return (
  //     <Box>
  //       <Loading />
  //     </Box>
  //   );
  // }

  return (
    <Box>
      <Button color="yellow" onClick={() => setCreateModal(true)}>Add Service</Button>
      <Modal opened={isCreateModal} 
          onClose={() => {
          setCreateModal(false)
          form.reset()
        }} title="Add Service" centered>
        <form onSubmit={form.onSubmit((values) => handleCreate(values))}>
          <TextInput label="Service Name" placeholder="Enter name here" {...form.getInputProps('name')} />

          {/* <div className="upload-image">
          <img src={showImage} alt={file.name} />
          </div> */}
          <div style={{ marginTop: 15 }}>
            <TextInput label="Select Image" type="file" required name="file" accept="image/png, image/jpg, image/jpeg" onChange={changeHandler} />
          </div>

          <div style={{ marginTop: 15 }}>
            <TextInput label="Select Selected Image" type="file" required name="file" accept="image/png, image/jpg, image/jpeg" onChange={changeSelectedFile} />
          </div>

          <Group position="center" mt="md">
            <Button color="black" onClick={hendleCancel}>
              Cancel
            </Button>
            <Button type="submit" color="yellow">Create</Button>
          </Group>
        </form>
      </Modal>
    </Box>
  );
};

export default Create;
