import React, { useState, useCallback } from 'react';
import { TextInput, Textarea, Box, Button, Modal, Group, FileInput, Input } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../../../components/Loading/Loading';

// API-Service   
import { sosUserListSevice, addSoSService } from '../../../../service/sosService';

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

import FileUpload from './FileUploader';

// Redux-Settings
import { setSosList, setTotalEntry } from '../../../../redux/sosSlice';

const Create = ({fetchData}) => {
  const [isCreateModal, setCreateModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const token = getToken();
  const navigate = useNavigate();
  const [file, setFile] = useState({});
  const [showImage, setImage] = useState();

  const form = useForm({
    initialValues: {
      name: '',
      image: '',
      number: '',
    },
    validate: {
      name: (value) => (value.length < 2 ? 'Please enter name' : null),
      number: (value) => (value.length < 9 ? 'Please enter number' : null),
      // image: (value) => (value.length < 2 ? 'Please select image' : null),
    },
  });

  const changeHandler = (event) => {
     setFile(event.target.files[0])
     setImage(URL.createObjectURL(file))
  };

  const handleCreate = async (values) => {
    const formData = new FormData()
    formData.append('image', file)
    formData.append('name', values?.name)
    formData.append('number', values?.number)
    formData.append('token', token)
    setLoading(true);
    const result = await addSoSService({ token, }, formData);

    if (result?.data?.status) {
      // Show success toast
      SuccessToast('User created successfully');

      // Call fetch callback function to fetch updated data
      fetchData();

      // Reset form and close modal
      setCreateModal(false);
      form.reset();
    } else {
      // Show error toast or handle other cases
      ErrorToast('Failed to create user');
    }
  
  };

  const hendleCancel = () => {
    setCreateModal(false);
    form.reset();
  };

  // if (loading) {
  //   return (
  //     <Box>
  //       <Loading />
  //     </Box>
  //   );
  // }

  return (
    <Box>
      <Button color="yellow" onClick={() => setCreateModal(true)}>Add SosUser</Button>
      <Modal opened={isCreateModal}  onClose={() => {
          setCreateModal(false)
          form.reset()
        }} title="Add SosUser" centered>
        <form onSubmit={form.onSubmit((values) => handleCreate(values))}>
          <TextInput label="Name" placeholder="Enter name here" {...form.getInputProps('name')} />
          <TextInput label="Phone Number" placeholder="Enter here" {...form.getInputProps('number')} />
          
          {/* <div className="upload-image">
          <img src={showImage} alt={file.name} />
          </div> */}
          <div style={{ marginTop: 15 }}>
            <TextInput label="Select Photo" type="file" required name="file" accept="image/png, image/jpg, image/jpeg" onChange={changeHandler} />
          </div>

          <Group position="center" mt="md">
            <Button color="dark" onClick={hendleCancel}>
              Cancel
            </Button>
            <Button color="yellow" type="submit">Create</Button>
          </Group>
        </form>
      </Modal>
    </Box>
  );
};

export default Create;
