import React from 'react';
import './invoice.css';
import moment from 'moment';
import image from '../../../../assets/images/logo.svg'

const MechanicInvoice = ({ data }) => (
  <>
    <div className="admin">
      <section className="main-layout">
        <div className="logo-parent">
          <img className="logo-icon" loading="lazy" alt="logo" src={image} />
          <h1 className="dashboard">INVOICE</h1>
        </div>
      </section>
      <section className="navigation-panel">
        <div className="navigation-items">
          <div className="navigation-title-wrapper">
            <div className="navigation-title">
              <div className="dashboard1">Requested DATE</div>
              <span style={{color: '#202839'}}>{moment(data.created_at).format('DD-MM-YYYY')}</span>
            </div>
          </div>
          <div className="navigation-title1">
            <div className="dashboard3">INVOICE #</div>
            <span style={{color: '#202839'}}>INV-{data.id}</span>
          </div>
        </div>
        <div className="content-area">
          <div className="content-header">
            <div className="dashboard5">Mechanic Info</div>
          </div>
          <div className="dashboard-grid">
            <div className="grid-item-row">
              <div className="grid-item">
                <div className="dashboard6">Name</div> 
              </div>
              <div className="grid-item1">
                <div className="dashboard7">:</div>
              </div>
              <div className="grid-item2">
                <div className="dashboard8">{data.mechanic?.company_name || data?.mechanic?.legal_name}</div>
              </div>
            </div>
            <div className="grid-item-row1">
              <div className="dashboard-wrapper">
                <div className="dashboard9">Email</div>
              </div>
              <div className="dashboard-container">
                <div className="dashboard10">:</div>
              </div>
              <div className="dashboard-frame">
                <div className="dashboard11">{data.mechanic.email}</div>
              </div>
            </div>
            <div className="grid-item-row2">
              <div className="frame-div">
                <div className="dashboard12">Phone</div>
              </div>
              <div className="dashboard-wrapper1">
                <div className="dashboard13">:</div>
              </div>
              <div className="dashboard-wrapper2">
                <div className="dashboard14">+6 {data.mechanic.phone}</div>
              </div>
            </div>
            <div className="grid-item-row3">
              <div className="dashboard-wrapper3">
                <div className="dashboard15">Bank Name</div>
              </div>
              <div className="dashboard-wrapper4">
                <div className="dashboard16">:</div>
              </div>
              <div className="dashboard-wrapper5">
                <div className="dashboard17">{data.store_bank.bank?.name}</div>
              </div>
            </div>
            <div className="grid-item-row4">
              <div className="dashboard-wrapper6">
                <div className="dashboard18">Account no.</div>
              </div>
              <div className="dashboard-wrapper7">
                <div className="dashboard19">:</div>
              </div>
              <div className="dashboard-wrapper8">
                <div className="dashboard20">{data.store_bank.account_number}</div>
              </div>
            </div>
            <div className="grid-item-row5">
              <div className="dashboard-wrapper9">
                <div className="dashboard21">Requested Amount</div>
              </div>
              <div className="dashboard-wrapper10">
                <div className="dashboard22">:</div>
              </div>
              <div className="dashboard-wrapper11">
                <div className="dashboard23"> {data.amount ? `RM ${data.amount.toFixed(2)}` : '--'}</div>
              </div>
            </div>
          </div> 
        </div>
      </section>
    </div>
    </>
)


export default MechanicInvoice;
