import React, { useState, useEffect } from 'react';
import { Box, Image, Switch } from '@mantine/core';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import Loading from '../../../../components/Loading/Loading';

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// Delete and Update
import DeleteUpdate from './DeleteUpdate';

// API-Service
import { customerListService, customerUpdateStatusService } from '../../../../service/customer.Service';
import { PlanListingServiceCustomer, CreateDataServiceDistance } from '../../../../service/planService';

// Redux-Settings
import {
  setCustomerList,
  setTotalEntry,
  selectFilterTerm,
  selectTotalEntry,
  setFilteredList,
  selectFilteredList,
  selectCustomerList,
  selectMechanicList,
  setMechanicList,
} from '../../../../redux/customerSlice';

// Auth-Image
import AuthImage from '../../../../components/AuthImage/AuthImage';

import PlaceHolderThumb from '../../../../assets/images/placeholderThumb.png';

const ListingTable = () => {
  const filterTag = useSelector(selectFilterTerm);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const myData = useSelector(selectCustomerList);
  // const mechanicData = useSelector(selectMechanicList);
  const Listing = useSelector(selectMechanicList).slice(-4);
  const mechanicData = useSelector(selectMechanicList);
  const mechanicDataFiltered = mechanicData
    .filter(item =>
      item.name === 'minimum_withdraw' ||
      item.name === 'extra_payment_commission_breakdown' ||
      item.name === 'extra_payment_commission_service' ||
      item.name === 'mechanic_max_distance'
    )
    .map(item => ({
      ...item,
      name: item.name === 'minimum_withdraw' ? 'mech_minimum_withdraw' : item.name
    }));
  console.log('filtered data', mechanicDataFiltered)

  const customerData = useSelector(selectCustomerList)

  const customerDataFiltered = customerData
    .filter(item =>
      item.name === 'minimum_withdraw' ||
      item.name === 'breakdown-service-charge'
    )
   

  const totalEntry = useSelector(selectTotalEntry);

  const token = getToken();
  const navigate = useNavigate();

  const [pageCount, setPageCount] = useState(1);
  const countPerPage = 10;

  const fetchData = async (filterTerm, page) => {
    const result = await customerListService({ filterTerm, page, countPerPage, token });
    if (result?.data?.status) {
      setLoading(false);
      dispatch(setCustomerList(result?.data?.data?.customer_variables_list));
      dispatch(setFilteredList(result?.data?.data?.customer_variables_list));
      dispatch(setTotalEntry(result?.data?.data?.customer_variables_list.length));
    } else {
      setLoading(false);
      ErrorToast(result);
      if (
        result?.data?.errorMessage === "jwt expired" ||
        result?.data?.errorMessage === "invalid token" ||
        result?.data?.errorMessage === "invalid auth"
      ) {
        removeUserSession();
        navigate('/');
      }
    }
  };

  const mechanicDataFetch = async () => {
    const result = await PlanListingServiceCustomer({ token });
    if (result?.data?.status) {
      setLoading(false);
      dispatch(setMechanicList(result?.data?.data?.mechanic_variables_list));
    } else {
      setLoading(false);
      ErrorToast(result);
    }
  };

  const statusUpdate = async (rowRecord) => {
    const statusUpdateresult = await customerUpdateStatusService({ id: rowRecord.id, token });
    if (statusUpdateresult?.data?.status) {
      SuccessToast(statusUpdateresult?.data?.message)
      fetchData(filterTag, pageCount);
    } else {
      ErrorToast(statusUpdateresult);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData(filterTag, pageCount);
    mechanicDataFetch();
    const timeoutId = setTimeout(() => 3000);
    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, [filterTag, pageCount]);

  const columns = [
    // {
    //   name: 'Video Thumb',
    //   selector: (row) =>(
    //     row?.file ? (
    //       <AuthImage src={row?.file} />
    //     ) : (
    //       <Image
    //         src={PlaceHolderThumb}
    //         alt="Avatar Thumb"
    //         sx={(_) => ({
    //           height: '80px',
    //           width: '80px',
    //           overflow: 'hidden',
    //         })}
    //       />
    //     )),
    //   maxWidth: '200px',
    // },
    // {
    //   name: 'ID',
    //   selector: (row) => row.admin_id,
    //   maxWidth: '450px',
    // },
    {
      name: 'Name',
      selector: (row) => {
        console.log('row', row)

        let showData = ''
        if (row.name === 'minimum_withdraw') {
          showData = 'Minimum Withdraw Customer'
        }
        if (row.name === 'mech_minimum_withdraw') {
          showData = 'Minimum Withdraw Mechanic'
        }
        if (row.name === 'breakdown-service-charge') {
          showData = 'Breakdown Service Charge'
        }
        if (row.name === 'mechanic_max_distance') {
          showData = 'Mechanic Max Distance'
        }
        if (row.name === 'extra_payment_commission_breakdown') {
          showData = 'Payment Commission Breakdown'
        }
        if (row.name === 'extra_payment_commission_service') {
          showData = ' Payment Commission Service'
        }
        return showData
      },
      maxWidth: '450px',
    },
    {
      name: 'Value',
      selector: (row) => {
        if (row.name === 'mechanic_max_distance') {
          return `${row.value} KM`;
        }
        if (row.name === 'extra_payment_commission_service' || row.name === 'extra_payment_commission_breakdown') {
          return ` ${row.value} %`;
        }
        return `RM ${row.value}`;
      },
      sortable: true,
      maxWidth: '100px',
      minWidth: '250px'
    },


    // {
    //   name: 'Created Date',
    //   selector: (row) => moment(row.created_at).format("DD-MM-YYYY"),
    //   maxWidth: '300px',
    // },

    {
      name: 'Action',
      selector: (row) => <DeleteUpdate tableRecord={row} />,
      sortable: true,
      minWidth: '120px'
    },
  ];

  if (loading) {
    return (
      <Box
        sx={() => ({
          margin: '0 auto',
          minHeight: 440,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        })}
      >
        <span className="visually-hidden"><Loading /></span>
      </Box>
    );
  }

  return (
    <Box
    >
      <Box className="rui-filemanager-content">
        <Box className="table-responsive-lg mb-20 inner-content-fix">
          <DataTable
            className="rui-datatable rui-filemanager-table table mb-20 mt-10"
            columns={columns}
            data={[...mechanicDataFiltered, ...customerDataFiltered]} // Combine mechanic and customer data
            key={Listing?.id}
            highlightOnHover
            responsive
            //  pagination
            paginationServer
            paginationTotalRows={totalEntry}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            onChangePage={(page) => setPageCount(page)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ListingTable;