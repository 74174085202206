import React, { useState, useEffect } from 'react';
import { Menu, Button, Image, Textarea, Modal, Group, Box, TextInput, Notification, ActionIcon } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconCheck, IconX } from '@tabler/icons';
import { FiEdit, FiTrash, FiEye, FiMoreVertical } from "react-icons/fi";


// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// API-Service  
import { dataListingService, UpdateSingleDataService, dataRemoveService } from '../../../../service/couponService';

// Redux-Settings
import { setSingleData } from '../../../../redux/aboutSlice';

const DeleteUpdate = ({ tableRecord }) => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isNotification, setNotification] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const detail = useSelector(selectStateSingleData);
  const token = getToken();

  // All Record-update Controller
  const handleUpdateModal = () => {
    
    navigate(`/admin/mechanic/string`)
    dispatch(setSingleData(tableRecord))
  };

  // useEffect(() => {
  //   setFromValue(tableRecord);
  // }, [tableRecord]);

  const hendleDeleteModal = () => {
    setIsDeleteModal(true);
  };

  const hendleCancel = () => {
    setIsDeleteModal(false);
  };

  return (
    <Box>

      <Menu control={<ActionIcon><FiMoreVertical size={18} /></ActionIcon>}>
        <Menu.Item onClick={handleUpdateModal} icon={<FiEdit size={14} />}>
          Edit
        </Menu.Item>
        {/* <Menu.Item
          onClick={hendleDeleteModal}
          icon={<FiTrash size={14} />}>
          Delete
        </Menu.Item> */}
      </Menu>
      {isNotification ? <Notification icon={<IconCheck size={18} />} color="teal" title="Notification">Coupon created successfully</Notification> : ''}

      {/* <Modal opened={isDeleteModal} onClose={() => setIsDeleteModal(false)} title="Please Confirm" centered>
        <Group position="center">
          <Button color="dark" onClick={hendleCancel}>
            Cancel
          </Button>
          <Button color="yellow" onClick={hendleDelete}>Delete</Button>
        </Group>
      </Modal> */}
    </Box>
  );
};

export default DeleteUpdate;
